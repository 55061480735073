import React from "react";

function Page1(){

    return (
        <div style={{minHeight: 100}}>
            Page 1 Bob!
        </div>
    );
}

export default Page1;